<template>
<v-app>
    <div>
        <v-toolbar
                dense
                color="primary"
                dark
                extended
                extension-height="120"
                flat
        >
            <v-toolbar-title  slot="extension" style="margin-left: 8px !important" >                               

                <h4>Dependentes</h4> 
                <h6>Contrato: {{id_c_s.Referencia}}</h6>
                <h6>Data: {{ id_c_s.DataContrato | formatDate }} </h6>


                <v-layout row >
                    <v-flex wrap>
                        <v-btn outline small fab color="white" to="/home" class="outine-2">
                              <i class="fa fa-users fa-2x" ></i>
                        </v-btn>
                    </v-flex>
                   
                   
                    
                </v-layout>
            </v-toolbar-title>
           
        </v-toolbar>

    <v-dialog
      v-model="dialog_edit"
      persistent
      max-width="1200px"
    >
      <v-card>
        <v-card-title class="text-h5 blue-grey darken-1 text-white">
          <span  class="headline text-white" style="color:white;">Atualização de Dependente</span>

        </v-card-title>

        <v-card-text>

        <v-container>
         <v-container grid-list-md>  
            <v-form ref="form" v-model="valid" lazy-validation> 

            <v-layout wrap>

            

                 <v-flex xs12>
                    <v-text-field  v-model="item.cpf" v-mask="'###.###.###-##'"  Required  label="CPF*" :rules="[v => !!v || 'Campo Obrigatório']"    box></v-text-field>
                 </v-flex>

                  <v-flex xs12 sm6>
                      <v-text-field v-model="item.prefixoTelefone" minlength="2" v-mask="'##'"  label="DDD" box></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                      <v-text-field v-model="item.telefone" label="Telefone Fixo" minlength="6"  box></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                      <v-text-field v-model="item.prefixoCelular" minlength="2" v-mask="'##'"  label="DDD" box></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                      <v-text-field v-model="item.celular" minlength="6" label="Celular" box></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>
                      <v-text-field v-model="item.email" label="Email" box></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6>

                      <v-select  v-model="item.grauInstrucao"   :items="this.escolaridade" label="Escolaridade"  box></v-select>
                  </v-flex>


                  <v-flex xs12 sm6>
                      <v-select  v-model="item.estadoCivil"  :items="this.estadocivil" label="Estado Civil"  box></v-select>
                  </v-flex>

                   <v-flex xs12>
                    <v-text-field  v-model="item.observação"  label="Observação"  box></v-text-field>
                 </v-flex>

             </v-layout>
            </v-form>

         </v-container> 
        </v-container>

        </v-card-text>


        <v-divider></v-divider>


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text flat
            @click="dialog_edit = false"
          >
            Sair
          </v-btn>

          <v-btn
            color="primary"
             @click="atualizar"
          >
            Confirmar
          </v-btn>
        </v-card-actions>



      </v-card>
    </v-dialog>

      <v-dialog v-model="dialog_msg" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Mensagem</v-card-title>
                <v-card-text>{{msg_dialog}}</v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" flat @click="dialog_msg = false" >OK</v-btn>
                </v-card-actions>
            </v-card>
            </v-dialog>


        <v-container >
            <v-layout v-resize="onResize" >
                <div class="mx-auto"  >
                  <v-data-table   :rows-per-page-items="[10, 20, 50]" v-if="onLoadDate" :hide-headers="isMobile" :class="{mobile: isMobile}"
                        :headers="headers"
                        :items="beneficiarios"
                        class="elevation-1">

                        <template slot="items" slot-scope="props">
                       
                        <tr v-if="!isMobile">

                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true" >   {{ props.item.Beneficiario.NomeCompleto }} </td>
                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true" >  {{ props.item.Beneficiario.DataNascimento | formatDate }} </td>
                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true" >  {{ props.item.Beneficiario.PrefixoTelefone }} -  {{ props.item.Beneficiario.Telefone }} </td>
                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true"> {{ props.item.Beneficiario.PrefixoCelular }} - {{ props.item.Beneficiario.Celular }} </td>
                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true"> {{ props.item.Beneficiario.Email }} </td>
                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true"> {{ props.item.Beneficiario.GrauInstrucao.Nome }} </td>
                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true"> {{ props.item.Beneficiario.EstadoCivil.Nome }}  </td>

                        <td class="text-xs-left" v-if="props.item.RegistroAtivo === true">
                          <div>
                            <v-btn disabled small color="info" v-if="props.item.GrauRelacao.Nome !== 'TITULAR'" @click="ver(props.item)">EDITAR</v-btn>

                          </div>
                        </td>
                        </tr>
                        <tr v-else>
                            <td>
                              <ul class="flex-content"  v-if="props.item.RegistroAtivo === true">
                                
                                <li class="flex-item" data-label="Nome">  {{ props.item.Beneficiario.NomeCompleto }} </li>
                                <li class="flex-item" data-label="Nascimento">{{ props.item.Beneficiario.DataNascimento | formatDate }}</li>
                                <li class="flex-item" data-label="Telefone">{{ props.item.Beneficiario.PrefixoTelefone }} -  {{ props.item.Beneficiario.Telefone }}</li>
                                <li class="flex-item" data-label="Celular">{{ props.item.Beneficiario.PrefixoCelular }} - {{ props.item.Beneficiario.Celular }}</li>
                                <li class="flex-item" data-label="Email">{{ props.item.Beneficiario.Email }}</li>
                                 <li class="flex-item" data-label="Grau Instrucao">{{ props.item.Beneficiario.GrauInstrucao.Nome }} </li>
                                 <li class="flex-item" data-label="Estado Cívil">{{ props.item.Beneficiario.EstadoCivil.Nome }}</li>

                                <li class="flex-item" data-label=""  v-if="props.item.RegistroAtivo === true">
                                        <v-btn disabled small color="info" v-if="props.item.GrauRelacao.Nome !== 'TITULAR'" @click="ver(props.item)">EDITAR</v-btn>
                                </li>
                                 
                              </ul>
                            </td>
                          </tr>



                        </template>
                    </v-data-table>
                  

                    <v-card-actions v-if="onLoadDate" > 
                    <v-spacer></v-spacer>

                        <v-btn
                            color="primary"
                            text
                            @click="finalizar" 
                        >
                            SAIR
                        </v-btn>
                    </v-card-actions>
                </div>  
                
            </v-layout>

        </v-container>
    </div>

    








</v-app>
</template>

<script>
import { http } from '../../services/config'
import authHeader from '../../services/auth-header';
import { mapGetters, mapActions } from 'vuex'
import ConsultaBandeira from '../../services/bandeira';

    export default {
       

        data() {
            return {
                   
                   id_c_s: {},
                   parcela: '',
                   item:{},
                   item_selected:{},
                   dialog_msg: false,
                   msg_dialog: '',
                   dialog_edit: false,
                   onLoadDate: false,
                   dialog_card: false,
                   dialog_barras: false,
                   showload: false,
                    isMobile: false,
                  
                   estadocivil: [
                     'SOLTEIRO (A)',
                      'CASADO (A)',
                      'VIÚVO (A)',
                      'DIVORCIADO (A)',
                      'DESQUITADO (A)',
                      'COMPANHEIRO (A)',
                      'AMAZIADO (A)',
                      'SEPARADO (A)',
                      'UNIAO ESTAVEL',
                      'OUTROS',
                      'INDEFINIDO'
                  ], 

                  escolaridade: [
                    'SEM ESCOLARIDADE',
                    'ENSINO FUNDAMENTAL',
                    'ENSINO MÉDIO',
                    'SUPERIOR COMPLETO',
                    'SUPERIOR INCOMPLETO',
                    'PÓS GRADUAÇÃO COMPLETA',
                    'PÓS GRADUAÇÃO INCOMPLETA'
                  ], 

                   parentescos: [
                      'CONJUGE OU COMPANHEIRO(A)',
                      'PAI',
                      'MÃE',
                      'SOGRO(A)',
                      'FILHO(A)',
                      'NETO(A)',
                      'IRMÃO',
                      'AVÓ(Ô)',
                      'PRIMO(A)',
                      'TIO(A)',
                      'SOBRINHO(A)',
                      'NORA',
                      'GENRO',
                      'CUNHADO(A)',
                      'OUTROS',
                      'NENHUM',


                    ], 


                   headers: [
                            { text: 'Nome', sortable: false },
                            { text: 'Nascimento', sortable: false },
                            { text: 'Telefone', sortable: false, fixed: true, width: "180px"  },
                            { text: 'Celular', sortable: false, width: "180px"  },
                            { text: 'Email', sortable: false },
                            { text: 'Grau de Instrução', sortable: false },
                            { text: 'Estado Civil', sortable: false,  width: "130px"  },

                            ],
                    beneficiarios: [],

                    rules: [
                        value => !!value || 'Required.'
                    ],

                    


                    select: null,
                    vencimentos: [
                        '01',
                        '05',
                        '15',
                        '20',
                        '25'

                    ], 


            }
        },
      
        mounted() {
        

        //var self = this
            this.id_c_s = JSON.parse(sessionStorage.getItem('id_c_s'))
            this.lista_dependente()
            this.item_selected = {};
            this.item = {};
    
        },

    
        methods:{

                openPagamento(item){
                    this.dialog_card = true
                    this.item_parcela = item
                },

               
               
                unde(value){
                  alert(value)
                    if (value === undefined) {
                                      //  alert('false')
                        return false
                    }else{

                      return true
                    }

                },

                onResize() {
                      if (window.innerWidth < 769)
                        this.isMobile = true;
                      else
                        this.isMobile = false;
                    },

   

               
                  
                ver(item_selecionado){
                        
                        this.item_selected = item_selecionado;
                        this.dialog_edit = true;
                        this.item.id = item_selecionado.Beneficiario.Id;
                        this.item.prefixoTelefone = item_selecionado.Beneficiario.PrefixoTelefone
                        this.item.telefone = item_selecionado.Beneficiario.Telefone
                        this.item.prefixoCelular = item_selecionado.Beneficiario.PrefixoCelular
                        this.item.celular = item_selecionado.Beneficiario.Celular
                        this.item.email = item_selecionado.Beneficiario.Email
                        this.item.estadoCivil = item_selecionado.Beneficiario.EstadoCivil.Nome
                        this.item.grauInstrucao = item_selecionado.Beneficiario.GrauInstrucao.Nome
                        this.item.cpf = item_selecionado.Beneficiario.InscricaoAtiva
                        this.item.contrato = this.id_c_s.Referencia
                        this.item.dispositivo = 'WEB'
                        this.item.observação = ""

                },

                atualizar(){

                      if (this.$refs.form.validate()) {
                    
                        this.item_selected.Beneficiario.PrefixoTelefone = this.item.prefixoTelefone
                        this.item_selected.Beneficiario.Telefone = this.item.telefone
                        this.item_selected.Beneficiario.PrefixoCelular = this.item.prefixoCelular
                        this.item_selected.Beneficiario.Celular = this.item.celular
                        this.item_selected.Beneficiario.Email = this.item.email
                        this.item_selected.Beneficiario.EstadoCivil.Nome = this.item.estadoCivil
                        this.item_selected.Beneficiario.GrauInstrucao.Nome = this.item.grauInstrucao
                        this.item_selected.Beneficiario.InscricaoAtiva = this.item.cpf
                        this.item_selected.Beneficiario.Contrato = this.item.contrato
                        this.item_selected.Beneficiario.dispositivo = 'WEB'
                      
                      
                      //this.msg_dialog = 'Os dados foram enviados para análise. Em Até 72 horas seu cadastro estará atualizado';
                      //this.dialog_msg = true;
                      this.dialog_edit = false;
                      
                      http.post('ClienteDIO/Atualizar/', this.item_selected, {  
                        //http.post('Beneficiario/', this.item, {
                                headers: authHeader()
                            }).then(response =>{
                            
                              if(response.status = 200){
                                if(response.data.Status === 0){
                                
                                  this.msg_dialog = "Parabéns, Seu endereço foi atualizado sucesso!";
                                  this.dialog_msg = true;
                                  this.dialog_edit = false; 
                                  
                                }else{
                                
                                    this.msg_dialog = response.data.Description;
                                    this.dialog_msg = true;
                                    this.dialog_edit = false; 

                                  }

                                 }
                                })
                                .catch(function (error) {
                                        this.msg_dialog = "Ops, Servidor com problemas, por favor tente novamente mais tarde.",
                                          this.dialog_msg = true;
                                          this.dialog_edit = false;             

                                });
                    }


             

                },
              
               finalizar(){  
                  
                   this.resposta_processamento = false
                   this.$router.push('/home')
                },

                lista_dependente(){
                     http.get('ContratoBeneficiarioDIO/Listar/'+ this.id_c_s.Id, {
                        headers: authHeader()
                    }).then(response =>{

                            this.beneficiarios = response.data.ResponseData;
                            this.onLoadDate = true

                        })
                        .catch(function (error) {

                        });     
                 },
                
         }
       
    }

</script>

<style>
    .outine-2 {
        border: 2px solid white;
    }

    .card--flex-toolbar {
        margin-top: -124px;
    }

    .learn-more-btn {
        text-transform: initial;
        text-decoration: underline;
    }

      .mobile {
      color: #333;
    }

    @media screen and (max-width: 768px) {
      .mobile table.v-table tr {
        max-width: 100%;
        position: relative;
        display: block;
      }

      .mobile table.v-table tr:nth-child(odd) {
        border-left: 6px solid deeppink;
      }

      .mobile table.v-table tr:nth-child(even) {
        border-left: 6px solid cyan;
      }

      .mobile table.v-table tr td {
        display: flex;
        width: 100%;
        border-bottom: 1px solid #f5f5f5;
        height: auto;
        padding: 10px;
      }

      .mobile table.v-table tr td ul li:before {
        content: attr(data-label);
        padding-right: .5em;
        text-align: left;
        display: block;
        color: #999;

      }
      .v-datatable__actions__select
      {
        width: 50%;
        margin: 0px;
        justify-content: flex-start;
      }
      .mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
        background: transparent;
      }

    }
    .flex-content {
      padding: 0;
      margin: 0;
      list-style: none;
      flex-wrap: wrap;
      width: 100%;
    }

    .flex-item {
      padding: 5px;
      height: 40px;
      font-weight: bold;
    }
</style>